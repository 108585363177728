*,
*::before,
*::after{
  margin:0;
  padding:0;
  box-sizing: border-box;
}


/*html{
  scroll-behavior: smooth;
  height:100dvh;
}*/

body{
  overflow-x: hidden;
  font-family: Poppins;
  background-color: black;
  --fs-sm: clamp(0.8rem, 0.17vi + 0.76rem, 0.89rem);
  --fs-base: clamp(1rem, 0.34vi + 0.91rem, 1.19rem);
  --fs-md: clamp(1.25rem, 0.61vi + 1.1rem, 1.58rem);
  --fs-lg: clamp(1.56rem, 1vi + 1.31rem, 2.11rem);
  --fs-xl: clamp(1.95rem, 1.56vi + 1.56rem, 2.81rem);
  --fs-xxl: clamp(2.44rem, 2.38vi + 1.85rem, 3.75rem);
  --fs-xxxl: clamp(3.05rem, 3.54vi + 2.17rem, 5rem);
}


canvas{
  position: absolute;
  max-width: 100vw;
  max-height: 100vh;
  z-index: 0;
}

video{
  background-position: center;
  background-size: cover;
}

h1{
  color: white;
  font-weight: 800;
  font-size: 8rem;
  text-transform: uppercase;
}


h3{
  color: white;
  font-weight: 800;
  font-size: 3vw;
  text-transform: uppercase;
  line-height: 1.225em;
}

h4{
  color: white;
  font-weight: 200;
  font-size: 1.725rem;
  text-transform: uppercase;
  line-height: 1.525em;
}

.hide{
  display: none;
}

html.lenis, html.lenis body {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto !important;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-scrolling iframe {
  pointer-events: none;
}


@media (min-aspect-ratio: 16/9) {
  video {
    width: 100%;
    height: auto;
  }
}
@media (max-aspect-ratio: 16/9) {
  video {
    width: auto;
    height: 100%;
  }
}

#storerocket-widget {
    font-size: 1rem;
    min-height: 500px;
    position: relative;
    overflow: hidden;
    visibility: visible !important;
    width: 100%;
    border: solid 1px #323232 !important;
    background: #121212;
    padding: 0;
    text-align: left;
    box-sizing: border-box;
    font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
}

.storerocket-result-website-link a{
    display: inline-block !important;
    font-size: 12px !important;
    color: #fff !important;
    font-weight: 600 !important;
    background-color: #020202 !important;
    border-radius: 2px !important;
    padding: 3px 8px !important;
}

#storerocket-search {
    position: relative;
    height: 70px;
    background: #121212 !important;
    width: 100%;
    padding: 14px 20px;
    border-bottom: solid 1px #282828 !important;
    z-index: 16;
}

#storerocket-widget .storerocket-query .storerocket-search-field {
    font-weight: 600;
    font-size: 13px;
    line-height: normal;
    height: 40px;
    padding: 0 35px 0 35px;
    border: none;
    color: #eee!important;
    background: #282828 !important;
    width: 100%;
}

#storerocket-widget .storerocket-dropdown .storerocket-dropdown-item:hover {
    background: #333 !important;
}

#storerocket-widget .storerocket-dropdown {
    z-index: 500;
    position: absolute;
    margin-top: 5px;
    top: 100%;
    color: white !important;
    background: #282828 !important;
    padding: 6px 10px;
    box-shadow: 0 8px 17px rgba(0, 0, 0, .1);
    border-radius: 2px;
    min-width: 180px;
    opacity: 0;
    pointer-events: none !important;
    transform: scale(.95);
    transition-property: transform, opacity;
    transition-duration: .25s;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
    align-self: center;
    border-bottom: none;
    border-top-color: #282828 !important;
}

.mapboxgl-popup-content {
    position: relative;
    color: white;
    background: #282828 !important;
    border-radius: 3px;
    box-shadow: 0 10px 30px rgb(0 0 0 / 15%);
    padding: 15px 20px 15px;
    pointer-events: auto;
}

.storerocket-popup-name {
    color: #fff !important;
    display: block;
    font-size: 15px;
    line-height: 24px;
    font-weight: 600;
    padding-right: 60px;
}

.storerocket-popup-address {
    margin-top: 10px;
    margin-bottom: 8px;
    display: block;
    font-size: 12px;
    line-height: 20px;
    font-weight: 300;
    color: #fff !important;
}

.storerocket-popup-buttons-get-directions {
    color: #c6c6c6 !important;
    font-size: 13px;
    font-weight: 600;
}

.storerocket-popup-phone, .storerocket-popup-email, .storerocket-popup-url {
    font-weight: 500;
    color: #fff !important;
    font-size: 13px;
}

.storerocket-spinner-ripple {
    text-decoration: none;
    color: #fff;
    width: 6px;
    height: 6px;
    background: #2ea2f6 !important;
    margin: 0 auto;
    border-radius: 9999px;
    -webkit-animation: ripple 1s linear infinite;
    animation: ripple 1s linear infinite;
}

#storerocket-widget .storerocket-radius-button, #storerocket-widget .storerocket-filters-button, #storerocket-widget .storerocket-submit-location-button {
    height: 40px;
    line-height: 40px;
    text-align: center;
    padding: 0 15px;
    font-weight: 500;
    color: #d4d3d3 !important;
    background: #282828 !important;
    border: none;
    outline: none !important;
    font-size: 13px;
}

#storerocket-panel {
    overflow: hidden !important;
    position: relative;
    top: 0;
    left: 0;
    width: 35%;
    float: left;
    background: #121212 !important;
    z-index: 14;
    min-height: 500px;
    box-shadow: 6px 0 42px rgba(0, 0, 0, 0.1);
}

.storerocket-result {
    overflow: hidden;
    padding: 15px 30px 15px 15px;
    position: relative;
    cursor: pointer;
    background: #282828 !important;
    box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.03);
    border-radius: 3px;
    margin: 10px 0;
    display: flex;
}

.storerocket-result-name {
    font-size: 14px;
    font-weight: 600;
    color: #d4d3d3 !important;
}

.storerocket-result-address {
    margin-top: 10px;
    font-size: 12px;
    line-height: 20px;
    font-weight: 300;
    color: #b3b3b3 !important;
}

.storerocket-result-phone {
    margin-top: 10px;
    font-size: 12px;
    line-height: normal;
    font-weight: 300;
    color: #b3b3b3 !important;
}

#storerocket-widget .storerocket-result-website-link {
    display: inline-block;
    font-size: 12px;
    color: #fff;
    font-weight: 600;
    background-color: #8d38ff !important;
    border-radius: 2px;
    padding: 3px 8px;
}

/* Preloader
-------------------------------------------------------*/

/*#mySite
{
 display: none;
}

.preloader{
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  height: 100vh;
  width: 100vw;

}
*/

/* Loader Styles start here */
.loader-wrapper {
  --line-width: 5px;
  --curtain-color:black;
  --outer-line-color: #a8dadc;
  --middle-line-color: #457b9d;
  --inner-line-color: #1d3557;
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  z-index:1000;
}

.loader {
  display:flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width:100vw;
  height:100vh;
  z-index:1001;
}

/*.loader:before {
  content:"";
  position: absolute;
  top:4px;
  left:4px;
  right:4px;
  bottom:4px;
  border:var(--line-width) solid transparent;
  border-top-color: var(--inner-line-color);
  border-radius:100%;
  -webkit-animation: spin 3s linear infinite;
          animation: spin 3s linear infinite;
}

.loader:after {
  content:"";
  position: absolute;
  top:14px;
  left:14px;
  right:14px;
  bottom:14px;
  border:var(--line-width) solid transparent;
  border-top-color: var(--middle-line-color);
  border-radius:100%;
  -webkit-animation: spin 1.5s linear infinite;
          animation: spin 1.5s linear infinite;
}

@-webkit-keyframes spin {
  0%   { 
    -webkit-transform: rotate(0deg); 
      -ms-transform: rotate(0deg); 
          transform: rotate(0deg);
  }
  100% { 
    -webkit-transform: rotate(360deg); 
      -ms-transform: rotate(360deg); 
          transform: rotate(360deg);
  }
}
@keyframes spin {
  0%   { 
    -webkit-transform: rotate(0deg); 
      -ms-transform: rotate(0deg); 
          transform: rotate(0deg);
  }
  100% { 
    -webkit-transform: rotate(360deg); 
      -ms-transform: rotate(360deg); 
          transform: rotate(360deg);
  }
}*/

.loader-wrapper .loader-section {
  position:fixed;
  top:0;
  background:var(--curtain-color);
  width:51%;
  height:100%;
  z-index:1000;
}

.loader-wrapper .loader-section.section-left {
  left:0
}
.loader-wrapper .loader-section.section-right {
  right:0;
}

/* Loaded Styles */ 
.loaded .loader-wrapper .loader-section.section-left {
  transform: translateX(-100%);
  transition: all 0.7s 0.3s cubic-bezier(0.645,0.045,0.355,1.000);
}
.loaded .loader-wrapper .loader-section.section-right {
  transform: translateX(100%);
  transition: all 0.7s 0.3s cubic-bezier(0.645,0.045,0.355,1.000);
}
.loaded .loader {
  opacity: 0;
  transition: all 0.3s ease-out;
}
.loaded .loader-wrapper {
  visibility: hidden;
  transform:translateY(-100%);
  transition: all .3s 1s ease-out;
}

.welcome-p{
color: #FFF;
text-align: center;
text-shadow: 0px 4px 40px #1D69FF;
font-family: Poppins;
font-size: 3.225vw;
font-style: normal;
text-transform: uppercase;
font-weight: 600;
line-height: normal;
}

/*.column{
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex:1;
  gap: 1.325rem;
}

.row {
  display: flex;
  align-content: center;
  width: 100vw;
  padding: 0 1rem;
  gap: .725rem;
  flex-direction: column;
  flex-wrap: wrap;
}*/

.video-container-bhp{
  position: absolute;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.video-container-bhp video{
  min-width: 100%;
  min-height: 100%;
  position: absolute;
}

/*-------DESKTOP STYLES---------*/


@media (min-width:800px){


/*.column{
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex:1;
  gap: 1.325rem;
}

.row {
  display: flex;
  align-content: center;
  width: 100vw;
  padding: 0 1rem;
  gap: .725rem;
  flex-direction: column;
  flex-wrap: wrap;
}*/


.wrapper{
  bottom: 0;
  width: 98vw;
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  pointer-events: none;
}


.wrapper h3{
color: #FFF;
font-family: Poppins;
font-size: 4rem;
font-style: normal;
font-weight: 800;
line-height: 4.125rem;
width: 100%;
padding:4rem;
}

#planets{
  height: 100vh;
  width: 100vw;
}


#container{
  width: 100vw;
  height: 100vh;
  position: relative;
}

#micro-reel{
display: flex;
justify-content: center;
align-items: center;
width: 100vw;
height: 100vh;
background-color: #121212;
overflow: hidden;
}

.video-container{
  position: absolute;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.video-container video{
  min-width: 100%;
  min-height: 100%;
  filter: blur(30px);
  position: absolute;
}

.video-container-bhp{
  position: absolute;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.video-container-bhp video{
  min-width: 100%;
  min-height: 100%;
  position: absolute;
}

#vid2{
  object-fit: cover;
  border-radius: 1.5rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.micro-reel-inner{
  height:80%;
  width:70%;
  background-color: #333;
  overflow: hidden;
  border-radius: 1.5rem;
  box-shadow: 0px 4px 44px 0px rgba(0, 0, 0, 0.35);
  z-index: 2;
}

.micro-reel-inner video{
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);

}

.homepage-p{
color: #FFF;
text-align: center;
text-shadow: 0px 4px 40px #1D69FF;
font-family: Poppins;
font-size: 1.225vw;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.welcome-p{
color: #FFF;
text-align: center;
text-shadow: 0px 4px 40px #1D69FF;
font-family: Poppins;
font-size: 2.225vw;
font-style: normal;
text-transform: uppercase;
font-weight: 600;
line-height: normal;
}

.liveresin-p{
color: #FFF;
/*text-shadow: 0px 4px 40px #1D69FF;*/
font-family: Poppins;
font-size: 1.225vw;
font-style: normal;
font-weight: 400;
line-height: normal;
}

#micro-welcome{
  height: 340vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #121212;
  background-image: url("assets/ml-bg-04.png");
  filter: contrast(100%);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow:hidden;
}

#micro-welcome:after {
  content: "";
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  pointer-events: none;
  background-image: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0, 1) 90%);
  width: 100%;
  height: 14em;
}

.micro-01{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flavors-01{
  z-index: 2;
  width: 100%;
}

.main-01{
  position: absolute;
  z-index: 1;
  width: 60%;
}

.micro-02{
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  z-index: 2;
  row-gap: 2em;
  width: 80vw;
}

.wrapper2{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}

.pl-1{
  height: 40%;
}

.pl-2{
  height: 40%;
}

.wrapper3{
  display: flex;
  width: 100%;
  height: 100%;
  bottom: 10px;
  position: relative;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.microverse{
height: 100%;
}

.micro-block{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap:2em;
  z-index: 2;
  padding-top: 20rem;
}
.micro-block p{
  width: 70%;
}

#live-resin{
position:relative;
width: 100dvw;
height: 300dvh;
display: flex;
justify-content: flex-start;
flex-direction: column;
align-items: center;
overflow: hidden;
}

#live-resin:after {
  content: "";
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  pointer-events: none;
  background-image: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0, 1) 90%);
  width: 100%;
  height: 14em;
}

.lr-01{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  object-fit: cover;
  pointer-events: none;
}
.lr-02{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  object-fit: cover;
  pointer-events: none;
}

.lr-03{
  position: absolute;
  top: 1000px;
  left: 0;
  width: 100%;
  object-fit: cover;
  pointer-events: none;
}


.wrapper5{
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  color: white;
  width: 70%;
  gap: 1rem;
  padding-top: 20em;
  text-align: center;
}


#FFT{
  height: 200px;
}

.wrapper5 h3{
  font-size: 8vw;
}

.wrapper6{
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  top: 10rem;
}

.gold-01{
  position: relative;
  z-index: 2;
  left: 10rem;
  flex-shrink: 0;
  pointer-events: none;
}
.chrome-01{
  position: relative;
  z-index: 2;
  left: 0;
  pointer-events: none;
}
.rose-01{
  position: relative;
  z-index: 2;
  left: -15rem;
  pointer-events: none;
}

.flex-ctn{
  position: relative;
  bottom: -240px;
  height: 100%;
  display: flex;
  flex-direction:row;
  z-index: 1;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: center;
  align-items: center;
}

.flex-wrapper{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 1.125rem;
  color: white;
  width: 50%;

}

.flex-ctn img{
  height: 16em;
}

#quick-strike{
position:relative;
width: 100dvw;
height: 250dvh;
display: flex;
justify-content: flex-start;
flex-direction: column;
align-items: center;
overflow: hidden;
}

.qs-01{
  position: absolute;
  top: 500px;
  left: 0;
  width: 100%;
  object-fit: cover;
  pointer-events: none;
}
.qs-02{
  position: relative;
  top: 500px;
  left: 0;
  height: 650px;
  object-fit: cover;
  z-index: 2;
  pointer-events: none;
}
.qs-03{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  object-fit: cover;
  pointer-events: none;
}
/*.qs-03{
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  object-fit: cover;
  pointer-events: none;
}
*/
.flex-ctn-2{
  position: relative;
  bottom: -400px;
  height: 100%;
  display: flex;
  flex-direction:row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: center;
  align-items: center;
  overflow: hidden;
}


.flex-wrapper-2{
  display: flex;
  position: absolute;
  gap: 1rem;
  padding: 0 1rem;
  flex-direction: column;
  color: white;
  width: 70%;
  align-items: flex-start;
}

.flex-ctn-2 img{
  height: 100vh;
  width: 100vw;
}



.video-container-3{
  display: flex;
  position: relative;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  width: 100vw;
}

.flex-ctn-3{
  position: relative;
  top: 200px;
  height: 100%;
  display: flex;
  flex-direction:row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: center;
  align-items: center;
  z-index: 2;
}

.flex-wrapper-3{
  display: flex;
  flex-direction: column;
  color: white;
  width: 40%;
}

.flex-ctn-3 img{
  height: 16em;
}

#vid4{
  position: relative;
  z-index: 1;
  top: -100px;
}
#map{
  height: 100dvh;
  width: 100dvw;
}



#mainline-block{
  height: 100vh;
  width: 100vw;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  pointer-events: none;
  text-align: center;
  background-position: center;
  background-size: cover;
  background-image: url("assets/6544097.jpg");
}

#mainline-block:after {
  content: "";
  position: absolute;
  z-index:1;
  bottom: 0;
  right: 0;
  pointer-events: none;
  background-image: linear-gradient(to left, rgb(255,0,128), rgba(0,0,0,1) 40%);
  width: 40%;
  height: 100%;
  filter: blur(517px);
}

#mainline-block:before {
  content: "";
  position: absolute;
  z-index:1;
  bottom: 0;
  left: 0;
  pointer-events: none;
  background-image: linear-gradient(to right, rgb(128,0,255), rgba(0,0,0,1) 40%);
  width: 40%;
  height: 100%;
  filter: blur(517px);
}

#mainline-block:backdrop{
  content: "";
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  pointer-events: none;
  background-image: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,1) 90%);
  width: 100%;
  height: 20em;
}


#mainline-block p{
  color: white;
}

.ml-wrapper{
display: flex;
position: absolute;
z-index: 2;
width: 50vw;
gap: 2rem;
height: 100%;
flex-direction: column;
flex-wrap: nowrap;
align-content: center;
justify-content: center;
align-items: center;
}

#mainline-spec{
  height: 240vh;
  width: 100vw;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  pointer-events: none;

}

.ml-01{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  object-fit: cover;
  pointer-events: none;
}

.ml-02{
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.ml-03{
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.mainline-wrapper{
    display: flex;
    position: absolute;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    justify-content: center;
}

.ml-04{
  width: 90%;
}

.ml-05{
  position: absolute;
  bottom:100px;
  height: 600px;
  pointer-events: none;
}

.ml-06{
  width: 60%;
}


#mainline-grid{
  height: 100%;
  width: 100vw;
  display: grid;
  grid-template-columns: 1fr;
  flex-direction: column;
  color: white;
  padding: 1em;

}

.ml-product{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 1.5rem;
  padding: 3rem
}

.ml-product h3{
  font-size: 3rem;

}

.ml-bar{
  height: 440px;
}

.image-wrapper{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
}

.img-wrapper-2{
  display: flex;
  width: 100vw;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.flex-grid-halfs {
  display: flex;
  justify-content: space-between;
}

.flex-grid-halfs .ml-product{
  width: 50%;
}

#mainline{
  position:relative;
  width: 100%;
  height:100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-image: url("assets/planet-grid.png");
}

/*#mainline:before{
  content: "";
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  pointer-events: none;
  background-image: linear-gradient(to top, rgba(0,0,0,0), rgba(0,0,0,1) 90%);
  width: 100%;
  height: 20em;
}

#mainline:after {
  content: "";
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  pointer-events: none;
  background-image: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,1) 90%);
  width: 100%;
  height: 20em;
}*/


#dual-sec-1{
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  height: 200vh;
  width: 100vw;
  overflow: hidden;
  object-fit: cover;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('assets/dual-arcade.png');
}

#dual-sec-2{
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.dual-wrapper{
  display: flex;
  justify-content: center;
  width: 69rem;
  height: 45.75rem;
  position: relative;
  top: -6rem;
  align-items: center;
  border-radius: 30px;
  overflow: hidden;
}

.arcade-video{
  width: 100%;
  height: auto;
  border-radius:30px;
}

.video-container-3{
  display: flex;
  position: relative;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  width: 100dvw;
}

.flex-ctn-3{
  position: relative;
  top: 200px;
  height: 100%;
  display: flex;
  flex-direction:row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: center;
  align-items: center;
  z-index: 2;
}

.flex-wrapper-3{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  color: white;
  width: 40%;
}

.flex-ctn-3 img{
  height: 16em;
}

.hero-title{
   position:absolute;
   bottom: 100px;
   z-index: 3;
}

.hero-sec-p{
  display: flex;
  bottom: 0;
  height: 200px;
  width: 100vw;
  position: absolute;
  z-index: 4;
  padding: 0 3rem;
  color: white;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  background-image: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,1) 90%);
}

.hero-block-wrapper{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
}

.hero-block-wrapper img{
  height: 140px;
  align-content: center;
}

.hero-block-slug{
  width: 50%;
}

.hero-sec-p p{
  font-size: 1rem;
}

.hero-sec-p h3{
  font-size: 3.225rem;
}
.hero-sec-p h4{
  font-size: 3rem;
}

.mobile{
  display: none !important;
}

#contact{
  display: flex;
  height: 110vh;
  width: 100vw;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  flex-wrap: nowrap;
}

#detail-dual{
height: 100vh;
}

.row{
  display: flex;
  flex-direction: row;
  flex-wrap:wrap;
  width: 100vw;
  padding: 0 2rem;
  gap: .725rem;
}

.column{
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex:1;
  gap: .725rem;
}

.center{
  align-items: center;
}

.mainline-battery{
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  height: 100vh;
  overflow: clip;
}

.mainline-battery-img-1{
  width: 600px;
  position: relative;
  bottom: -300px;
}

.mainline-battery-img-2{
  width: 600px;
}

}
/*-------END STYLES---------*/



/*-------MOBILE STYLES---------*/


@media (max-width:799px){

.pd-sm{
  margin-top:4rem;
}

.pd-sm-bm{
  margin-bottom:4rem;
}

.center{
  align-items: center;
}

.loader img{
  height: 50%;
}

.mainline-battery{
  display: flex;
  color: white;
  height: 100vh;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  overflow: clip;
}


.mainline-battery-img-1{
  width: 320px;
  position: relative;
  bottom: 0;
}

.mainline-battery-img-2{
  width: 100%;
}

.column{
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex:1;
  gap: 1.325rem;
}

.row {
  display: flex;
  align-content: center;
  width: 100vw;
  padding: 0 1rem;
  gap: .725rem;
  flex-direction: column;
  flex-wrap: wrap;
}

#contact{
  display: flex;
  height: 180vh;
  width: 100vw;
  padding: 0 0.125rem;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-wrap: nowrap;
}

.dual-wrapper{
  display: flex;
  justify-content: center;
  width: 22rem;
  height: 15.75rem;
  flex-shrink: 0;
  align-items: center;
  border-radius: 30px;
  overflow: hidden;
}

.arcade-video{
  width: auto;
  height: 100%;
}

#dual-sec-1{
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  object-fit: cover;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('assets/dual-arcade.png');
}

#dual-sec-2{
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  height: 130vh;
  width: 100vw;
  overflow: hidden;
}

.video-container-3 {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
    width: 100dvw;
}

.flex-ctn-3{
  position: relative;
  top: 200px;
  height: 100%;
  display: flex;
  flex-direction:row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: center;
  align-items: center;
  z-index: 2;
}
.flex-wrapper-3{
  display: flex;
  align-items: flex-start;
  gap: .325rem;
  flex-direction: column;
  color: white;
  width: 100%;
  padding: 1em;
}

#detail-dual{
height: 100vh;
}


.hero-block-slug{
  width: 100%;
  text-align: center;
}
.hero-sec-p {
    display: flex;
    bottom: 0;
    height: 200px;
    position: absolute;
    z-index: 4;
    padding: 0;
    color: white;
    gap: 1em;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 90%);
    justify-content: center;
    align-content: center;
}

.hero-sec-p p{
  display: none;
}

.hero-block-wrapper img {
    height: 80px;
    align-content: center;
}

.desktop{
  display: none !important;
}

h1{
  color: white;
  font-weight: 800;
  font-size: 10vw;
  text-transform: uppercase;
}


h3{
  color: white;
  font-weight: 800;
  font-size: 9vw;
  text-transform: uppercase;
  line-height: 1.225em;
}

h4{
  color: white;
  font-weight: 200;
  font-size: 1.725rem;
  text-transform: uppercase;
  line-height: 1.525em;
}

.wrapper{
  bottom: 0;
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  pointer-events: none;
}
.wrapper h3{
color: #FFF;
font-family: Poppins;
font-size: 4rem;
font-style: normal;
font-weight: 800;
line-height: 4.125rem;
width: 100%;
padding:4rem;
}

#planets{
  height: 100vh;
  width: 100%;
}


#container{
  width: 100vw;
  height: 100vh;
  position: relative;
}

#micro-reel{
display: flex;
justify-content: center;
align-items: center;
width: 100vw;
height: 100vh;
background-color: #121212;
overflow: hidden;
}

.video-container{
  position: absolute;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.video-container video{
  min-width: 100%;
  min-height: 100%;
  filter: blur(30px);
  position: absolute;
}

#vid2{
  object-fit: cover;
  border-radius: 1.5rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.micro-reel-inner{
  height:90vh;
  width:90vw;
  background-color: #333;
  overflow: hidden;
  border-radius: 1.5rem;
  box-shadow: 0px 4px 44px 0px rgba(0, 0, 0, 0.35);
  z-index: 2;
}

.micro-reel-inner video{
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);

}

.homepage-p{
color: #FFF;
text-align: center;
text-shadow: 0px 4px 40px #1D69FF;
font-family: Poppins;
font-style: normal;
font-weight: 400;
line-height: normal;
}

#micro-welcome{
  height: 220vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #121212;
  background-image: url("assets/ml-bg-04.png");
  filter: contrast(100%);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow:hidden;
}

#micro-welcome:after {
  content: "";
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  pointer-events: none;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,0)), color-stop(90%, rgba(0,0,0, 1)));
  background-image: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0, 1) 90%);
  width: 100%;
  height: 14em;
}

.micro-01{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flavors-01{
  z-index: 2;
  width: 500px;
}

.main-01{
  position: absolute;
  z-index: 1;
  width: 65%;
}

.micro-02{
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  z-index: 2;
  width: 100vw;
  padding: 0 0.525rem;
  row-gap: 2em;
}

.wrapper2{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}

.pl-1{
  height: 30vh;
}

.pl-2{
  height: 30vh;
}

.wrapper3{
  display: flex;
  width: 100%;
  height: 100%;
  bottom: 10px;
  position: relative;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.microverse{
width: auto;
height: 22.75rem;
}

.micro-block{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap:2em;
  z-index: 2;
  padding-top: 10rem;
}
.micro-block p{
  padding: 0 1rem;
  width: 100vw;
}

#live-resin{
position:relative;
width: 100vw;
height: 240vh;
display: flex;
justify-content: flex-start;
flex-direction: column;
align-items: center;
overflow: hidden;
}

#live-resin:after {
  content: "";
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  pointer-events: none;
  background-image: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0, 1) 90%);
  width: 100%;
  height: 14em;
}

.lr-01{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  object-fit: cover;
  pointer-events: none;
}
.lr-02{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  object-fit: cover;
  pointer-events: none;
}

.lr-03{
  position: absolute;
  top: 1000px;
  left: 0;
  width: 100%;
  object-fit: cover;
  pointer-events: none;
}


.wrapper5{
  display: flex;
  text-align:center;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  gap: 1rem;
  color: white;
  width: 100vw;
  padding: 0 1rem;
  padding-top: 20em;
}


#FFT{
  height: 130px;
}

.wrapper5 h3{
  font-size: 8vw;
}

.wrapper6{
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  top: 10rem;
  left: 0;
  z-index: 4;
  width: 100vw;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap
}

.gold-01{
  position: relative;
  z-index: 2;
  flex-shrink: 0;
  height: 200px;
  pointer-events: none;
}
.chrome-01{
  position: relative;
  z-index: 2;
  left: 0;
  height: 200px;
  pointer-events: none;
}
.rose-01{
  position: relative;
  z-index: 2;
  height: 200px;
  pointer-events: none;
}

.flex-ctn{
  position: relative;
  bottom:0;
  height: 100vh;
  display: flex;
  flex-direction:row;
  z-index: 1;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: center;
  align-items: center;
}

.flex-wrapper{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: white;
  width: 100vw;
  padding: 0 1rem;
  gap: 1rem;

}

.flex-ctn img{
  display: none;
}

#quick-strike{
position:relative;
width: 100vw;
height: 200vh;
display: flex;
justify-content: flex-start;
flex-direction: column;
align-items: center;
overflow: hidden;
}

.qs-01{
  position: absolute;
  top: 15%;
  left: 0;
  width: 100%;
  object-fit: cover;
  pointer-events: none;
}
.qs-02{
  position: relative;
  top: 200px;
  left: 0;
  height: 400px;
  object-fit: cover;
  z-index: 2;
  pointer-events: none;
}
.qs-03{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  object-fit: cover;
  pointer-events: none;
}
/*.qs-03{
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  object-fit: cover;
  pointer-events: none;
}
*/
.flex-ctn-2{
  position: relative;
  bottom: -200px;
  height: 100%;
  display: flex;
  flex-direction:row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: center;
  align-items: center;
  overflow: hidden;
}

.flex-wrapper-2{
  display: flex;
  position: absolute;
  gap: 1rem;
  padding: 0 1rem;
  flex-direction: column;
  color: white;
  width: 100vw;
  align-items: flex-start;
}

.flex-ctn-2 img{
  height: 150vh;
  width: 100vw;
}

#vid4{
  position: relative;
  z-index: 1;
  top: -100px;
}
#map{
  height: 100vh;
  width: 100vw;
}

#mainline{
  position:relative;
  width: 100vw;
  height:100vh;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  pointer-events: none;
  background-position: center;
  background-size: cover;
  background-color: #121212;
  background-image: url("assets/6544097.jpg");
}

#mainline:before{
  content: "";
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  pointer-events: none;
  background-image: linear-gradient(to top, rgba(0,0,0,0), rgba(0,0,0,1) 90%);
  width: 100%;
  height: 20em;
}

#mainline:after {
  content: "";
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  pointer-events: none;
  background-image: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,1) 90%);
  width: 100%;
  height: 20em;
}

.hero-title{
   position:absolute;
   bottom: 100px;
   z-index: 3;
}


#mainline-block{
  height: 100vh;
  width: 100vw;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  pointer-events: none;
  text-align: center;
  background-position: center;
  background-size: cover;
  background-image: url("assets/6544097.jpg");
}

#mainline-block:after {
  content: "";
  position: absolute;
  z-index:1;
  bottom: 0;
  right: 0;
  pointer-events: none;
  background-image: linear-gradient(to left, rgb(255,0,128), rgba(0,0,0,1) 40%);
  width: 40%;
  height: 100%;
  filter: blur(517px);
}

#mainline-block:before {
  content: "";
  position: absolute;
  z-index:1;
  bottom: 0;
  left: 0;
  pointer-events: none;
  background-image: linear-gradient(to right, rgb(128,0,255), rgba(0,0,0,1) 40%);
  width: 40%;
  height: 100%;
  filter: blur(517px);
}

#mainline-block:backdrop{
  content: "";
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  pointer-events: none;
  background-image: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,1) 90%);
  width: 100%;
  height: 20em;
}


#mainline-block p{
  color: white;
  font-size: 4vw;
}

.ml-wrapper{
display: flex;
position: absolute;
z-index: 2;
gap:1rem;
width: 90%;
height: 100%;
flex-direction: column;
flex-wrap: nowrap;
align-content: center;
justify-content: center;
align-items: center;
}

#mainline-spec{
  height: 100vh;
  width: 100vw;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  pointer-events: none;

}

.ml-01{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 90vh;
  object-fit: cover;
  pointer-events: none;
}

.ml-02{
  position: absolute;
  top: 100px;
  left: 50%;
  width: 100vw;
  transform: translate(-50%, -50%);
  pointer-events: none;
}
.ml-03{
  position: absolute;
  top: 100px;
  left: 50%;
  height: 200px;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.mainline-wrapper{
  position: absolute;
  bottom: 0;
  height: 45vh;
  width: 100vw;
}

.ml-04{
  width: 100vw;
  pointer-events: none;
}

.ml-05{
  position: absolute;
  bottom: 0;
  left: 5%;
  width: 410px;
  pointer-events: none;
}

.image-wrapper{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
}

.img-wrapper-2{
  position: absolute;
  bottom: 0;
  display: flex;
  height: 50vh;
  width: 100vw;
  justify-content: flex-start;
  overflow: hidden;
}


#mainline-grid{
  height: 1050vh;
  width: 100vw;
  display: flex;
  grid-template-columns: 1fr;
  gap: 1rem;
  flex-direction: column;
  color: white;

}

.ml-product{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 0.825rem;
  padding: 0 1rem;
}

.ml-product h3{
  font-size: 7vw;
}

.ml-product p{
  font-size: 4vw;
}

.ml-bar{
  height: 300px;
}

.flex-grid-halfs{
  display: flex;
  gap: 2rem;
  flex-direction: column;
  justify-content: space-between;
}

.flex-grid-halfs .ml-product{
  width: 100vw;
}

.wrapper2 img{
  display: none;
}


}

